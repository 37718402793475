import React, { FC, useContext, useState } from "react"
import { observer } from "mobx-react-lite"
import {
    ViewStyle,
    ScrollView,
    View,
    StyleSheet,
    Image,
    Dimensions,
    useColorScheme,
    TouchableOpacity,
    Platform,
} from "react-native"
import Carousel from 'react-native-reanimated-carousel';
import FeaturedCard from "../components/FeaturedCard"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../navigators"
import { Screen, Text } from "../components"
import themeContext from "../theme/themeContext"
import { useWindowDimensions } from "react-native"
import { responsiveHeight, responsiveWidth } from "react-native-responsive-dimensions"
import { Card } from "@rneui/themed"
import { TopGameCard } from "../components"
import theme from "../theme/theme"
import { ActionGame } from "../components/ActionGame"
import Draggable from "react-draggable"
import { isMobile } from "react-device-detect"
import { fc ,qc ,bd , cd} from "../constant/imagePath"
import AutoGrid from "../components/Autogrid"

// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../models"

// STOP! READ ME FIRST!
// To fix the TS error below, you'll need to add the following things in your navigation config:
// - Add `Sports: undefined` to AppStackParamList
// - Import your screen, and add it to the stack:
//     `<Stack.Screen name="Sports" component={SportsScreen} />`
// Hint: Look for the 🔥!

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const SportsScreen: FC<StackScreenProps<AppStackScreenProps, "Sports">> = observer(function SportsScreen() {
    const colorScheme = useColorScheme()

    const ITEM_WIDTH = 160 // Set the width of each child component
    const ITEM_SPACING = 20 // Calculate the spacing needed to spread children components equally

    const schema = colorScheme === "dark" ? theme.dark : theme.light

    const handleDrag = (e, data, type) => {
      const { width } = Dimensions.get("window")

      let noOfItems = type === "menu" ? 13 : type === "quiz" ? 3 : 20
      console.log(type, noOfItems, "event")
      let maxScrollWidth = (ITEM_SPACING + ITEM_WIDTH) * noOfItems - width

      if (data.x < -maxScrollWidth) {
        data.x = -maxScrollWidth
      }
      if (data.x > maxScrollWidth) {
        data.x = maxScrollWidth
      }
    }

    const CardsData = cd(schema)
  const filteredCardData = CardsData.filter((CardData) => CardData.background === schema.Sport);

    return (
      <Screen style={{ backgroundColor: schema.backgroundSecondary }}>
        <ScrollView horizontal={false} alwaysBounceVertical={true}>
          <AutoGrid itemWidth={160}>
          {filteredCardData.map((CardData, i) => (
              <TopGameCard
                key={`${CardData.id}-${i++}`}
                gameSrc={CardData.title}
                background={CardData.background}
                onGameClick={CardData.screen}
                Url={CardData.Url}
                {...CardData}
                {...TopGameCard}
              />
            ))}
          </AutoGrid>
        </ScrollView>
      </Screen>
    )
  },
)

function useStyles() {
  const { width, height } = useWindowDimensions()
  return StyleSheet.create({
    root: {
      flex: 1,
      flexDirection: "row",
    },
    mainContainer: {
      width: "100%",
      padding: "1rem",
      // border: "1px solid black",
      margin: "auto",
      userSelect: "none",
    },
    appHeader: {
      margin: 10,
      alignItems: "center",
    },
    logo: {
      width: responsiveWidth(60),
      height: responsiveHeight(8),
      resizeMode: "contain",
      marginTop: 20,
    },
    scrollview: {
      height: "100%",
      // Hide the horizontal scrollbar
      scrollbarWidth: 0,
      scrollbarHeight: 1,
      overflow: "scroll",

      // Hide the vertical scrollbar
      "-ms-overflow-style": "none",
    },

    topContainer: {
      alignItems: "center",
      flexDirection: "row",
      textTransform: "capitalize",
      // backgroundColor: "#E2EFFF",
      padding: 10,
    },
    buttonElements: {
      flexDirection: "row",
      flex: 1,
      width: "100%",
      alignItems: "center",
    },

    secondbox: {
      flex: 1,
      flexDirection: "row",
      padding: 10,
      marginBottom: 10,
      marginTop: 10,
    },
    secondBoxElement: {
      flex: 1,
      fontSize: 25,
      textAlign: "left",
      fontFamily: "Poppins",
      fontWeight: "bold",
    },
    secondBoxElementRight: {
      textAlign: "right",
      marginTop: 1,
      fontSize: 14,
      fontFamily: "Poppins",
      color: "#2DA8FC",
    },
    cardmenufirst: {
      flexDirection: "row",
      alignItems: "center",
      alignSelf: "center",
      overflow: "hidden",

      // maxWidth: "26rem"
    },
    Midlogoimgs: {
      width: 130,
      marginLeft: 4,
      height: 140,
      borderRadius: 6,
    },
    hoverImg: {
      width: 115,
      marginLeft: 13,
      height: 120,
      position: "absolute",
      top: 5,
      borderRadius: 6,
      flexDirection: "row",
    },
    topgames: {
      width: "100%",
    },
    CardViewGameBox: {
      flex: 1,
      margin: 5,
      flexDirection: "row",
      paddingBottom: 120,
    },
    CardViewGameBoxImg: {
      width: 127,
      height: 126,
      backgroundColor: "#767676",
      marginLeft: 5,
      alignItems: "center",
      borderRadius: 18,
    },
    CardViewGameBoxImges: {
      width: 127,
      height: 126,
      borderRadius: 18,
    },
    CardViewGameBoxText: {
      color: "white",
      margin: "auto",
      fontSize: 20,
      // fontFamily: "Poppins",
    },
    cardViewtext: {
      fontSize: 15,
    },
  } as any)
}
