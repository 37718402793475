import React, { FC, useContext, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
  View,
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  useColorScheme,
  Switch,
  ImageBackground
} from "react-native";
import { StackScreenProps } from "@react-navigation/stack";
import { Screen } from "../components";
import theme from "../theme/theme"
import { isMobile } from "react-device-detect";
import { useTheme } from "../constant/TheamContext";

import AsyncStorage from '@react-native-async-storage/async-storage';

import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../models"

// STOP! READ ME FIRST!
// To fix the TS error below, you'll need to add the following things in your navigation config:
// - Add `UserProfile: undefined` to AppStackParamList
// - Import your screen, and add it to the stack:
//     `<Stack.Screen name="UserProfile" component={UserProfileScreen} />`
// Hint: Look for the 🔥!

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const UserProfileScreen: FC<StackScreenProps<AppStackScreenProps, "UserProfile">> = observer(function UserProfileScreen() {
  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()
  const { themeColor, setThemeColor } = useTheme();
  const [isEnabled, setIsEnabled] = useState(false);
  const toggleTheme = () => {

    setThemeColor(prevColor => prevColor === "light" ? "dark" : "light");

  };
  // const toggleSwitch = () => 

  const schema = themeColor === "dark" ? theme.dark : theme.light;


  const handleLogout = async () => {
    try {
      // Update AsyncStorage
      await AsyncStorage.setItem('isLoggedIn', 'false');
      // Update state
      // Navigate to UserLogin screen
      navigation.navigate('UserLogin');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const styles = useStyles()

  // Pull in navigation via hook
  const navigation = useNavigation()
  return (
    <Screen style={{
      backgroundColor: schema.backgroundSecondary,
    }} preset="scroll">

      {!isMobile ? (
        <View>
          <View>
            <ImageBackground source={require("../../assets/images/ProfileBg.png")} style={{ width: '100%', height: 400, }} resizeMode="cover">
              <View style={{ width: '100%', backgroundColor: '#35354B', position: "absolute", bottom: -20, padding: 20, flexDirection: 'row', alignItems: 'center' }} >

                <View style={{ width: "30%", flexDirection: 'row', justifyContent: "space-between" }}>

                  <View style={{ alignItems: 'center', }}>
                    <Text style={{ color: 'white' }}>500</Text>
                    <Text style={{ color: '#636A98', marginTop: 5 }} >Total Game</Text>
                  </View>
                  <View style={{ alignItems: 'center' }}>
                    <Text style={{ color: 'white' }}>500</Text>
                    <Text style={{ color: '#636A98', marginTop: 5 }} >Total Win</Text>
                  </View>
                  <View style={{ alignItems: 'center' }}>
                    <Text style={{ color: 'white' }}>500</Text>
                    <Text style={{ color: '#636A98', marginTop: 5 }} >Total Loss</Text>
                  </View>
                </View>

                <View style={{ width: "40%", alignItems: 'center', position: 'relative', }}>

                  <View style={{ backgroundColor: '#35354B', padding: 10, position: 'absolute', top: -59, transform: [{ rotate: '45deg' }], width: 80, height: 80, }}>
                    <View style={{ width: 75, height: 75, borderColor: '#F3DB66', borderWidth: 3, }}>
                      <Image source={schema.user} style={{ width: 70, height: 70, transform: [{ rotate: '-45deg' }], }} />
                    </View>
                  </View>

                  <View style={{ marginTop: 40 }}>
                    <Text style={{ color: "white", fontSize: 20, fontWeight: "500" }}>Mohan123</Text>
                  </View>

                </View>

                <View style={{ width: "30%", flexDirection: 'row', justifyContent: "space-between" }}>
                  <View style={{ alignItems: 'center', }}>
                    <Text style={{ color: 'white' }}>India</Text>
                    <Text style={{ color: '#636A98', marginTop: 5 }} >Location</Text>
                  </View>
                  <View style={{ alignItems: 'center' }}>
                    <Text style={{ color: 'white' }}>500</Text>
                    <Text style={{ color: '#636A98', marginTop: 5 }} >Points</Text>
                  </View>
                  <View style={{ alignItems: 'center' }}>
                    <Text style={{ color: 'white' }}>50</Text>
                    <Text style={{ color: '#636A98', marginTop: 5 }} >Player Level</Text>
                  </View>
                </View>

              </View>
            </ImageBackground>
          </View>

          <View style={{ width: "100%", marginTop: 30, padding: 10, flexDirection: 'row' }}>

            <View style={{ width: '30%', }}>
              <View style={{ width: "100%", backgroundColor: '#35354B', padding: 20, borderRadius: 10, }}>
                <View style={{ width: 200, height: 40, flexDirection: 'row' }}>
                  <Text style={{ marginLeft: 10, color: "white", fontSize: 20, fontWeight: "700", }}>About Me</Text>
                </View>
                <View style={{ width: "100%", borderTop: '2px solid #8491F2', marginTop: 5, }}>
                  <Text style={{ color: "white", marginTop: 10, textAlign: "left", fontSize: 16 }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                  </Text>
                </View>

                <View style={{ marginTop: 20 }}>

                  <View style={{ flexDirection: "row", justifyContent: "space-between", }}>
                    <Text style={{ color: "#5963A0" }}>Joined Date</Text>
                    <Text style={{ color: "white" }}>Septembar, 20, 2022</Text>
                  </View>

                  <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: 10 }}>
                    <Text style={{ color: "#5963A0" }}>Location</Text>
                    <Text style={{ color: "white" }}>123, Flat No, Jaipur (Rajasthan)</Text>
                  </View>

                </View>

              </View>

              <View style={{ width: "100%", backgroundColor: '#35354B', padding: 20, marginTop: 20, borderRadius: 10 }}>
                <View style={{}}>
                  <View>
                    <Text style={{ color: "white", fontSize: 20, fontWeight: "700", }}>Balance</Text>
                  </View>

                  <View style={{ flexDirection: "row", marginTop: 10, borderTopWidth: 2, borderTopColor: '#8491F2' }}>

                    <View style={{ width: '50%', borderRightColor: "#8491F2", borderRightWidth: 2 }}>
                      <View style={{ justifyContent: "center", alignItems: 'center' }}>
                        <Image source={require('../../assets/images/GameCoin.png')} style={{ width: 150, height: 150, resizeMode: 'contain' }} />
                        <Text style={{ color: 'white', fontSize: 20, fontWeight: '600' }}>10,000</Text>
                        <Text style={{ color: 'white', fontSize: 20, }}>Game Coin</Text>
                      </View>
                    </View>

                    <View style={{ width: '50%', marginTop: 10, flexDirection: 'row', marginLeft: 20 }}>
                      <View style={{ justifyContent: "center", alignItems: 'center' }}>
                        <Image source={require('../../assets/images/GameCoin.png')} style={{ width: 150, height: 150, resizeMode: 'center' }} />
                        <Text style={{ color: 'white', fontSize: 20, fontWeight: '600' }}>2000</Text>
                        <Text style={{ color: 'white', fontSize: 20, }}>Game Coin</Text>


                      </View>
                    </View>

                  </View>
                </View>
              </View>

              <View style={{ width: "100%", backgroundColor: '#35354B', padding: 20, marginTop: 20, borderRadius: 10 }}>
                <Text style={{ color: "white", fontSize: 20, fontWeight: "700", }}>
                  User Setting
                </Text>
                <View style={{ marginTop: 30, borderTop: '2px solid #8491F2', }} >
                  <View style={{ marginTop: 10, flexDirection: 'row', marginLeft: 20, alignItems: 'center', }}>
                    <Text style={{ color: "white", fontSize: 18 }} >Light Mode</Text>

                    <Switch value={themeColor === "dark"} onValueChange={toggleTheme}
                      trackColor={{ false: '#ffff', true: '#0A0D39' }}
                      thumbColor={'#035380'}
                      style={{ marginLeft: 10, marginRight: 10, }} />
                    <Text style={{ color: "white", fontSize: 18 }}>Dark Mode</Text>
                  </View>

                  <TouchableOpacity onPress={() => navigation.navigate("Edit")} style={{ backgroundColor: '#5F53FD', alignItems: 'center', marginTop: 20, borderRadius: 10, }}>
                    <Text style={{ fontSize: 20, color: "white", paddingTop: 5, paddingBottom: 5, }}>
                      Edit Profile
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity  onPress={handleLogout}  style={{ backgroundColor: '#5F53FD', alignItems: 'center', marginTop: 20, borderRadius: 10, }}>
                    <Text style={{ fontSize: 20, color: "white", paddingTop: 5, paddingBottom: 5, }}>
                      Logout
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>

            <View style={{ width: '70%', padding: 10, borderRadius: 10, }}>

              <View style={{ width: '100%', justifyContent: 'space-between', flexDirection: 'row', }}>
                <View style={{ width: '49%', backgroundColor: '#35354B', padding: 20, borderRadius: 20, }}>
                  <View>
                    <Text style={{ color: "white", fontSize: 20, fontWeight: "700", }}>LeaderBoard Rank</Text>
                  </View>

                  <View style={{ marginTop: 10, borderTop: '2px solid #8491F2', }}>
                    <View style={{ width: '100%', marginTop: 10, flexDirection: 'row', marginLeft: 20, alignItems: "center", }}>
                      <Image source={schema.user} style={{ width: 50, height: 50, }} />
                      <Text style={{ color: 'white', fontSize: 20, marginLeft: 20 }}>#10</Text>
                      <Text style={{ color: 'white', fontSize: 20, marginLeft: 20 }}>Stinger_20</Text>
                    </View>
                    <View style={{}}>
                      <TouchableOpacity onPress={() => navigation.navigate("LeaderBoard")}>
                        <Text style={{ color: 'white', alignSelf: 'flex-end' }}>
                          View All
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
                <View style={{ width: '49%', backgroundColor: '#35354B', padding: 20, borderRadius: 20, }}>
                  <View>
                    <Text style={{ fontSize: 20, color: 'white', }}>Total Activity</Text>
                  </View>
                  <View style={{ flexDirection: "row", marginTop: 10, borderTop: '2px solid #8491F2', }}>
                    <View style={{ width: '100%', marginTop: 10, flexDirection: 'row', marginLeft: 20, alignItems: "center" }}>
                      <Text style={{ color: 'white' }}>No Activity Found</Text>
                    </View>
                  </View>
                </View>
              </View>

              <View style={{ width: '100%', backgroundColor: '#35354B', borderRadius: 20, padding: 20, marginTop: 20 }}>
                <View>
                  <Text style={{ fontSize: 20, color: 'white', }}>Game History  </Text>
                </View>

                <View style={{ marginTop: 10, borderTop: '2px solid #8491F2', flexDirection: 'row', width: "100%" }}>

                  <View style={{ marginTop: 20, alignItems: "center", width: '20%', }}>
                    <Text style={{ color: 'white', fontSize: 16, marginBottom: 10 }}>Game Room</Text>
                    <Image source={require('../../assets/games-icon/1.png')} style={{ width: 50, height: 50, borderRadius: 10, }} />
                  </View>


                  <View style={{ marginTop: 20, alignItems: "center", width: '20%', }}>
                    <Text style={{ color: 'white', fontSize: 16, marginBottom: 10 }}>Prize</Text>
                    <Text style={{ color: 'white', fontSize: 16, marginBottom: 10 }}>200$</Text>

                  </View>


                  <View style={{ marginTop: 20, alignItems: "center", width: '20%', }}>
                    <Text style={{ color: 'white', fontSize: 16, marginBottom: 10 }}>Player</Text>
                    <Text style={{ color: 'white', fontSize: 16, marginBottom: 10 }}>20</Text>
                  </View>


                  <View style={{ marginTop: 20, alignItems: "center", width: '20%', }}>
                    <Text style={{ color: 'white', fontSize: 16, marginBottom: 10 }}>Rank</Text>
                    <Text style={{ color: 'white', fontSize: 16, marginBottom: 10 }}>5</Text>
                  </View>


                  <View style={{ marginTop: 20, alignItems: "center", width: '20%', }}>
                    <Text style={{ color: 'white', fontSize: 16, marginBottom: 10 }}>Your Win</Text>
                    <Text style={{ color: 'white', fontSize: 16, marginBottom: 10 }}>28$</Text>
                  </View>
                </View>

              </View>


            </View>

          </View>

        </View>
      ) : (


        <>

          <View>

            <View style={{}}>
              <View>
                <ImageBackground source={require("../../assets/images/ProfileBg.png")} style={{ width: '100%', height: 400, }}>
                  <View style={{ width: '100%', backgroundColor: '#35354B', position: "absolute", bottom: -20, padding: 20, flexDirection: 'row', alignItems: 'center' }} >

                    <View style={{ width: "30%", justifyContent: "space-between" }}>

                      <View style={{ width: "100%", marginTop: 10, alignItems: 'center', flexDirection: 'row', }}>
                        <Text style={{ color: '#636A98', width: '70%' }} >Total Game</Text>
                        <Text style={{ color: 'white', width: '30%' }}>500</Text>
                      </View>
                      <View style={{ width: '100%', marginTop: 10, alignItems: 'center', flexDirection: 'row', }}>
                        <Text style={{ width: '70%', color: '#636A98', }} >Total Win</Text>
                        <Text style={{ color: 'white', width: "30%" }}>350</Text>
                      </View>
                      <View style={{ width: '100%', marginTop: 10, alignItems: 'center', flexDirection: 'row', }}>
                        <Text style={{ width: '70%', color: '#636A98', }} >Total Loss</Text>
                        <Text style={{ width: '30%', color: 'white', }}>150</Text>
                      </View>

                    </View>

                    <View style={{ width: "40%", alignItems: 'center', position: 'relative', }}>

                      <View style={{ backgroundColor: '#35354B', padding: 10, position: 'absolute', top: -70, transform: [{ rotate: '45deg' }], width: 80, height: 80, }}>
                        <View style={{ width: 75, height: 75, borderColor: '#F3DB66', borderWidth: 3, }}>
                          <Image source={schema.user} style={{ width: 70, height: 70, transform: [{ rotate: '-45deg' }], }} />
                        </View>
                      </View>

                      <View style={{ marginTop: 40 }}>
                        <Text style={{ color: "white", fontSize: 20, fontWeight: "500" }}>Mohan123</Text>
                      </View>

                    </View>

                    <View style={{ width: "30%", justifyContent: "space-between" }}>

                      <View style={{ width: "100%", marginTop: 10, alignItems: 'center', flexDirection: 'row', }}>
                        <Text style={{ color: '#636A98', width: '70%' }} >Location</Text>
                        <Text style={{ color: 'white', width: '30%' }}>India</Text>
                      </View>
                      <View style={{ width: '100%', marginTop: 10, alignItems: 'center', flexDirection: 'row', }}>
                        <Text style={{ width: '70%', color: '#636A98', }} >Points</Text>
                        <Text style={{ color: 'white', width: "30%" }}>500</Text>
                      </View>
                      <View style={{ width: '100%', marginTop: 10, alignItems: 'center', flexDirection: 'row', }}>
                        <Text style={{ width: '70%', color: '#636A98', }} >Player Level</Text>
                        <Text style={{ width: '30%', color: 'white', }}>50</Text>
                      </View>

                    </View>

                  </View>
                </ImageBackground>
              </View>

            </View>

            <View style={{ width: "100%", paddingBottom: 30, }}>

              <View style={{ width: '90%', marginTop: '60px', alignSelf: 'center', justifyContent: 'space-between' }}>

                <View style={{ width: "100%", backgroundColor: '#35354B', padding: 20, borderRadius: 10, }}>
                  <View style={{ width: 200, height: 40, flexDirection: 'row' }}>
                    <Text style={{ marginLeft: 10, color: "white", fontSize: 20, fontWeight: "700", }}>About Me</Text>
                  </View>
                  <View style={{ width: "100%", borderTop: '2px solid #8491F2', marginTop: 5, }}>
                    <Text style={{ color: "white", marginTop: 10, textAlign: "left", fontSize: 16 }}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </Text>
                  </View>

                  <View style={{ marginTop: 20 }}>

                    <View style={{ flexDirection: "row", justifyContent: "space-between", }}>
                      <Text style={{ color: "#5963A0" }}>Joined Date</Text>
                      <Text style={{ color: "white" }}>Septembar, 20, 2022</Text>
                    </View>

                    <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: 10 }}>
                      <Text style={{ color: "#5963A0" }}>Location</Text>
                      <Text style={{ color: "white" }}>123, Flat No, Jaipur (Rajasthan)</Text>
                    </View>

                  </View>

                </View>

                <View style={{ width: "100%", backgroundColor: '#35354B', padding: 20, marginTop: 20, borderRadius: 10 }}>
                <Text style={{ color: "white", fontSize: 20, fontWeight: "700", }}>
                  User Setting
                </Text>
                <View style={{ marginTop: 30, borderTop: '2px solid #8491F2', }} >
                  <View style={{ marginTop: 10, flexDirection: 'row', marginLeft: 20, alignItems: 'center', }}>
                    <Text style={{ color: "white", fontSize: 18 }} >Light Mode</Text>

                    <Switch value={themeColor === "dark"} onValueChange={toggleTheme}
                      trackColor={{ false: '#ffff', true: '#0A0D39' }}
                      thumbColor={'#035380'}
                      style={{ marginLeft: 10, marginRight: 10, }} />
                    <Text style={{ color: "white", fontSize: 18 }}>Dark Mode</Text>
                  </View>

                  <TouchableOpacity onPress={() => navigation.navigate("Edit")} style={{ backgroundColor: '#5F53FD', alignItems: 'center', marginTop: 20, borderRadius: 10, }}>
                    <Text style={{ fontSize: 20, color: "white", paddingTop: 5, paddingBottom: 5, }}>
                      Edit Profile
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity  onPress={handleLogout}  style={{ backgroundColor: '#5F53FD', alignItems: 'center', marginTop: 20, borderRadius: 10, }}>
                    <Text style={{ fontSize: 20, color: "white", paddingTop: 5, paddingBottom: 5, }}>
                      Logout
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>

                <View style={{ width: '100%', padding: 20, justifyContent: "space-between", borderRadius: 20, }}>

                  <View style={{ width: '100%', justifyContent: 'space-between', }}>

                    <View style={{ width: '100%', backgroundColor: '#35354B', padding: 20, borderRadius: 20, }}>
                      <View>
                        <Text style={{ color: "white", fontSize: 20, fontWeight: "700", }}>LeaderBoard Rank</Text>
                      </View>

                      <View style={{ marginTop: 10, borderTop: '2px solid #8491F2', }}>
                        <View style={{ width: '100%', marginTop: 10, flexDirection: 'row', marginLeft: 20, alignItems: "center", }}>
                          <Image source={schema.user} style={{ width: 50, height: 50, }} />
                          <Text style={{ color: 'white', fontSize: 20, marginLeft: 20 }}>#10</Text>
                          <Text style={{ color: 'white', fontSize: 20, marginLeft: 20 }}>Stinger_20</Text>
                        </View>
                        <View style={{}}>
                          <TouchableOpacity onPress={() => navigation.navigate("LeaderBoard")}>
                            <Text style={{ color: 'white', alignSelf: 'flex-end' }}>
                              View All
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                    <View style={{ width: '100%', backgroundColor: '#35354B', padding: 10, borderRadius: 20, marginTop: 20, }}>
                      <View>
                        <Text style={{ color: "white", fontSize: 20, fontWeight: "700", }}>Total Activity</Text>
                      </View>
                      <View style={{ flexDirection: "row", marginTop: 10, borderTop: '2px solid #8491F2', }}>
                        <View style={{ width: '100%', marginTop: 10, flexDirection: 'row', marginLeft: 20, alignItems: "center" }}>
                          <Text style={{ color: 'white' }}>No Activity Found</Text>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View style={{ width: '100%', marginTop: 20, backgroundColor: '#35354B', padding: 10, borderRadius: 20 }}>
                    <View>
                      <Text style={{ color: "white", fontSize: 20, fontWeight: "700", }}>Game History  </Text>
                    </View>


                    <View style={{ marginTop: 10, borderTop: '2px solid #8491F2', flexDirection: 'row', }}>


                      <View style={{ marginTop: 20, alignItems: "center", width: '20%', }}>
                        <Text style={{ color: 'white', fontSize: 10, marginBottom: 10 }}>Game Room</Text>
                        <Image source={require('../../assets/games-icon/1.png')} style={{ width: 50, height: 50, borderRadius: 10, }} />
                      </View>


                      <View style={{ marginTop: 20, alignItems: "center", width: '20%', }}>
                        <Text style={{ color: 'white', fontSize: 10, marginBottom: 10 }}>Prize</Text>
                        <Text style={{ color: 'white', fontSize: 10, marginBottom: 10, marginTop: 15 }}>200$</Text>

                      </View>


                      <View style={{ marginTop: 20, alignItems: "center", width: '20%', }}>
                        <Text style={{ color: 'white', fontSize: 10, marginBottom: 10 }}>Player</Text>
                        <Text style={{ color: 'white', fontSize: 10, marginBottom: 10, marginTop: 15 }}>20</Text>
                      </View>


                      <View style={{ marginTop: 20, alignItems: "center", width: '20%', }}>
                        <Text style={{ color: 'white', fontSize: 10, marginBottom: 10 }}>Rank</Text>
                        <Text style={{ color: 'white', fontSize: 10, marginBottom: 10, marginTop: 15 }}>5</Text>
                      </View>


                      <View style={{ marginTop: 20, alignItems: "center", width: '20%', }}>
                        <Text style={{ color: 'white', fontSize: 10, marginBottom: 10 }}>Your Win</Text>
                        <Text style={{ color: 'white', fontSize: 10, marginBottom: 10, marginTop: 15 }}>28$</Text>
                      </View>
                    </View>


                    <View style={{ marginTop: 5, flexDirection: 'row', alignItems: "center" }}>


                      <View style={{ marginTop: 20, alignItems: "center", width: '20%', }}>

                        <Image source={require('../../assets/games-icon/1.png')} style={{ width: 50, height: 50, borderRadius: 10, }} />
                      </View>


                      <View style={{ marginTop: 20, alignItems: "center", width: '20%', }}>

                        <Text style={{ color: 'white', fontSize: 10, marginBottom: 10 }}>200$</Text>

                      </View>


                      <View style={{ marginTop: 20, alignItems: "center", width: '20%', }}>

                        <Text style={{ color: 'white', fontSize: 12, marginBottom: 10 }}>20</Text>
                      </View>


                      <View style={{ marginTop: 20, alignItems: "center", width: '20%', }}>

                        <Text style={{ color: 'white', fontSize: 12, marginBottom: 10 }}>5</Text>
                      </View>


                      <View style={{ marginTop: 20, alignItems: "center", width: '20%', }}>

                        <Text style={{ color: 'white', fontSize: 12, marginBottom: 10 }}>28$</Text>
                      </View>




                    </View>

                  </View>


                </View>

              </View>

            </View>


          </View>
        </>

      )}

    </Screen>

  )
})


function useStyles() {
  return StyleSheet.create({
    userContainer: {
      width: 100,
      alignSelf: 'center',
      alignItems: 'center',
      borderRadius: 50,
      border: '2px solid white',
      position: "absolute",
      top: -60,
      backgroundColor: '#85D1DA',
    },
    shadowProp: {
      shadowColor: 'white',
      shadowOffset: { width: -2, height: 4 },
      shadowOpacity: 0.2,
      shadowRadius: 3,
    },
  })

}
