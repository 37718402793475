import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import {
  ViewStyle,
  useColorScheme,
  View,
  Dimensions,
  TouchableOpacity,
  ImageBackground,
  Image,
  Button,
  Modal,
  Alert,
  Picker,
  ScrollView,
} from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../navigators"
import { Screen, Text } from "../components"
import theme from "../theme/theme"
import { TextInput } from "react-native-gesture-handler"
import { useNavigation } from "@react-navigation/native"
import { useHeaderHeight } from "@react-navigation/elements"
import { isMobile } from "react-device-detect"
import { Card } from "@rneui/base"
import { useTheme } from "../constant/TheamContext"
import { Linking } from "react-native"
import axios from "axios"

import AsyncStorage from "@react-native-async-storage/async-storage"; // Import AsyncStorage


const accountSid = "AC20f580c70a58ec1365e351cf47f6ccdd"
const authToken = "c9d39f4c3d297a495a9d3ef385dce35b"
const twilioPhoneNumber = "+19209569569"

export const UserRegistrationScreen: FC<StackScreenProps<AppStackScreenProps, "UserRegistration">> =
  observer(function UserRegistrationScreen() {


    const { themeColor } = useTheme()
    const schema = themeColor === "dark" ? theme.dark : theme.light
    const windowWidth = Dimensions.get("window").width
    const { height } = Dimensions.get("window")
    const headerHeight = useHeaderHeight() + 0
    const [text, setText] = useState("")

    const [showModal, setShowModal] = useState(false)
    const navigation = useNavigation()

    const [otp, setOTP] = useState("")
    console.log("ENTERED OTP >>>>>", otp)
    const [isOTPFilled, setIsOTPFilled] = useState(false)
    const [canRequestOTP, setCanRequestOTP] = useState(true)
    const [delayTime, setDelayTime] = useState(15000) // Initial delay time in milliseconds (15 seconds)
    const [remainingTime, setRemainingTime] = useState(0);

    function generateOTP() {
      return Math.floor(100000 + Math.random() * 900000).toString()
    }
    
    useEffect(() => {
      let timer;
      if (!canRequestOTP) {
        setRemainingTime(delayTime / 1000);
        timer = setInterval(() => {
          setRemainingTime(prevTime => {
            if (prevTime <= 1) {
              clearInterval(timer);
              setCanRequestOTP(true);
              setDelayTime(prevTime => Math.min(prevTime * 2, 120000)); // Double the delay time with a max of 120 seconds
              return 0;
            }
            return prevTime - 1;
          });
        }, 1000);
      }
      return () => clearInterval(timer);
    }, [canRequestOTP, delayTime]);
    

    async function sendOTP(mobileNumber: string, otp: string) {
      const url = `https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Messages.json`
      console.log("mobileNumber:", selectedValue)
      const data = new URLSearchParams({
        Body: `Your OTP is ${otp}`,
        From: twilioPhoneNumber,
        To: `${selectedValue}${mobileNumber}`,
      })

      const auth = {
        username: accountSid,
        password: authToken,
      }

      try {
        const response = await axios.post(url, data, { auth })
        console.log("OTP sent:", response.data)
      } catch (error) {
        console.error("Error sending OTP:", error)
      }
    }

    const handleOTPChange = (input) => {
      const numericInput = input.replace(/\D/g, "")
      const truncatedInput = numericInput.slice(0, 6)
      setOTP(truncatedInput)
      setIsOTPFilled(truncatedInput.length === 6)
    }

    const [mobileNumber, setMobileNumber] = useState("")
    const handleMobileNumberChange = (input) => {
      const numericInput = input.replace(/\D/g, "")
      const truncatedInput = numericInput.slice(0, 10)
      setMobileNumber(truncatedInput)
    }

    const [selectedValue, setSelectedValue] = useState("+91")
    const [OtpError, setOTPError] = useState("")
    const [isPopoverOTPVisible, setIsPopoverOTPVisible] = useState(false)
    const [mobileNumberError, setMobileNumberError] = useState("")
    const [sentOtp, setSentOtp]= useState("")

    console.log("set SENT OTP", sentOtp)
    const handleOTPInputs = async () => {
      if (mobileNumber.length === 10 && canRequestOTP) {
        const otp = generateOTP()
        await sendOTP(mobileNumber, otp)
        setIsPopoverOTPVisible(true)
        setCanRequestOTP(false) // Disable OTP request button
        setSentOtp(otp); 
      } else if (mobileNumber.length !== 10) {
        setMobileNumberError("Please enter a valid 10-digit mobile number")
      }
    }
    
    const hidePopover = () => {
      setIsPopoverOTPVisible(false)
    }
    
    const handleVerify = async () => {
      if (otp == sentOtp) {
        try {
          await AsyncStorage.setItem("isLoggedIn", "true");
          setIsPopoverOTPVisible(false);
  
          // Check isLoggedIn after setting it
          const isLoggedIn = await AsyncStorage.getItem("isLoggedIn");
        
          // Navigate to HomeScreen if isLoggedIn is true
          if (isLoggedIn === "true") {
            navigation.navigate("HomeScreen");
            window.location.reload(); // Reload the page once
          } else {
            // Handle case where isLoggedIn is not 'true'
            console.warn("isLoggedIn is not true:", isLoggedIn);
          }
        } catch (error) {
          console.error('Error setting login status:', error);
          // Handle error if AsyncStorage setItem or getItem fails
          setOTPError("Error setting login status");
        }
      } else {
        setOTPError("Please enter a valid OTP");
      }
    };

   
  
   
    return (
      <ScrollView
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        alwaysBounceHorizontal={false}
        alwaysBounceVertical={false}
        style={{ backgroundColor: schema.backgroundSecondary }}
      >
        {!isMobile ? (
          <View style={{ flexDirection: "row" }}>
            <View
              style={{ width: windowWidth * 0.3, backgroundColor: "#24172E", position: "relative" }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  marginTop: 90,
                  height: height - headerHeight - 100,
                  borderTopLeftRadius: 50,
                  borderTopRightRadius: 50,
                  padding: 20,
                }}
              >
                <View style={{ alignItems: "center", marginTop: 50 }}>
                  <Text style={{ fontSize: 30, fontFamily: "poppins", fontWeight: "900" }}>
                    Login
                  </Text>
                </View>
                <View style={{ marginTop: 40, alignItems: "center" }}>
                  {mobileNumberError !== "" && (
                    <Text style={{ color: "red", marginTop: 5 }}>{mobileNumberError}</Text>
                  )}
                </View>
                <View style={{ flexDirection: "row", width: "100%", marginTop: 10 }}>
                  <Picker
                    selectedValue={selectedValue}
                    style={{
                      height: 40,
                      width: 100,
                      marginLeft: 20,
                      borderRadius: 10,
                      border: "2px solid #d5d7e3",
                    }}
                    onValueChange={(itemValue: React.SetStateAction<string>) =>
                      setSelectedValue(itemValue)
                    }
                  >
                    <Picker.Item label="+91" value="option1" />
                    <Picker.Item label="+1" value="option2" />
                    <Picker.Item label="+299" value="option3" />
                  </Picker>
                  <TextInput
                    placeholder="Enter Your Mobile Number"
                    value={mobileNumber}
                    onChangeText={handleMobileNumberChange}
                    style={{
                      width: 500,
                      marginLeft: 20,
                      borderRadius: 10,
                      padding: 10,
                      borderWidth: 2,
                      borderColor: "#d5d7e3",
                    }}
                    keyboardType="numeric"
                  />
                </View>
                <View style={{ width: "100%", alignItems: "center", marginTop: 30 }}>
                  <TouchableOpacity
                    onPress={handleOTPInputs}
                    style={{
                      width: "90%",
                      backgroundColor: "#070B28",
                      borderRadius: 10,
                      padding: 10,
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text
                      style={{
                        color: "white",
                        fontFamily: "poppins",
                        fontSize: 18,
                        paddingLeft: 10,
                      }}
                    >
                      {canRequestOTP ? 'Login' : `Wait ${remainingTime} seconds`}</Text>
                    <Image
                      source={require("../../assets/images/loginrightarrow.png")}
                      style={{ width: 20, height: 10, marginTop: 8, paddingRight: 10 }}
                    />
                  </TouchableOpacity>
                  {isPopoverOTPVisible && (
                    <Modal animationType="none" transparent={true} visible={isPopoverOTPVisible}>
                      <View style={{ flex: 1, width: windowWidth * 0.3 }}>
                        <View
                          style={{
                            height: "100%",
                            backgroundColor: "white",
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                            padding: 10,
                          }}
                        >
                          <View
                            style={{
                              width: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: 50,
                            }}
                          >
                            <Image
                              source={require("../../assets/images/OTPVarify.png")}
                              style={{ width: 100, height: 100 }}
                            />
                            <Text
                              style={{
                                fontSize: 20,
                                fontWeight: "900",
                                fontFamily: "",
                                marginTop: 20,
                              }}
                            >
                              Verification Code{" "}
                            </Text>
                            <Text
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                textAlign: "center",
                                width: "80%",
                              }}
                            >
                              Please type the Verification code sent to {mobileNumber}
                            </Text>
                          </View>
                          <View style={{ marginTop: 40, alignItems: "center" }}>
                            {OtpError !== "" && (
                              <Text style={{ color: "red", marginTop: 5 }}>{OtpError}</Text>
                            )}
                          </View>
                          <TextInput
                            placeholder="Enter OTP"
                            value={otp}
                            onChangeText={handleOTPChange}
                            style={{
                              width: "100%",
                              marginTop: 10,
                              padding: 10,
                              borderWidth: 1,
                              borderColor: "#d5d7e3",
                              borderRadius: 5,
                              alignItems: "center",
                              textAlign: "center",
                            }}
                            autoComplete="one-time-code"
                            keyboardType="numeric"
                          />
                          <View style={{ marginTop: 20 }}>
                            <TouchableOpacity
                              onPress={handleVerify}
                              style={{
                                backgroundColor: isOTPFilled ? "orange" : "#ECECEC",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: 10,
                              }}
                            >
                              <Text style={{ fontFamily: "" }}>Verify</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                              onPress={hidePopover}
                              style={{
                                backgroundColor: "#EAFAFF",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: 10,
                                marginTop: 10,
                              }}
                            >
                              <Text style={{ fontFamily: "" }}>Resend Otp</Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                    </Modal>
                  )}
                </View>
                <View style={{ justifyContent: "center", alignItems: "center" }}>
                  <Text
                    style={{
                      width: 250,
                      textAlign: "center",
                      fontFamily: "Poppins",
                      marginTop: 20,
                    }}
                  >
                    By Login, I agree to P9Games{" "}
                    <Text
                      style={{ textDecorationLine: "underline", color: "blue" }}
                      onPress={() => Linking.openURL("https://p9games.com/privacy-policy")}
                    >
                      Terms and Conditions
                    </Text>{" "}
                    and{" "}
                    <Text
                      style={{ textDecorationLine: "underline", color: "blue" }}
                      onPress={() => Linking.openURL("https://p9games.com/privacy-policy")}
                    >
                      Privacy Policy
                    </Text>
                  </Text>
                </View>
              </View>
              <View style={{ width: "100%", position: "absolute", top: 20 }}>
                <Image
                  source={require("../../assets/images/loginLogo.png")}
                  style={{ width: "100%", height: 100, resizeMode: "contain" }}
                />
              </View>
            </View>
            <Image
              source={require("../../assets/images/login.jpg")}
              style={{ width: "100%", height: height, opacity: 0.5 }}
            />
          </View>
        ) : (
          <>
            <View style={{ width: windowWidth, backgroundColor: "#24172E", position: "relative" }}>
              <View
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  marginTop: 300,
                  height: height - headerHeight - 300,
                  borderTopLeftRadius: 50,
                  borderTopRightRadius: 50,
                  padding: 20,
                }}
              >
                <View style={{ alignItems: "center", marginTop: 50 }}>
                  <Text style={{ fontSize: 30, fontFamily: "poppins", fontWeight: "900" }}>
                    Login
                  </Text>
                </View>
                <View style={{ marginTop: 40, alignItems: "center" }}>
                  {mobileNumberError !== "" && (
                    <Text style={{ color: "red", marginTop: 5 }}>{mobileNumberError}</Text>
                  )}
                </View>
                <View style={{ flexDirection: "row", width: "100%", marginTop: 10 }}>
                  <Picker
                    selectedValue={selectedValue}
                    style={{
                      height: 40,
                      width: 100,
                      marginLeft: 20,
                      borderRadius: 10,
                      border: "2px solid #d5d7e3",
                    }}
                    onValueChange={(itemValue: React.SetStateAction<string>) =>
                      setSelectedValue(itemValue)
                    }
                  >
                    <Picker.Item label="+91" value="option1" />
                    <Picker.Item label="+1" value="option2" />
                    <Picker.Item label="+299" value="option3" />
                  </Picker>
                  <TextInput
                    placeholder="Enter Your Mobile Number"
                    value={mobileNumber}
                    onChangeText={handleMobileNumberChange}
                    style={{
                      width: 500,
                      marginLeft: 20,
                      borderRadius: 10,
                      padding: 10,
                      borderWidth: 2,
                      borderColor: "#d5d7e3",
                    }}
                    keyboardType="numeric"
                  />
                </View>
                <View style={{ width: "100%", alignItems: "center", marginTop: 30 }}>
                  <TouchableOpacity
                    onPress={handleOTPInputs}
                    style={{
                      width: "90%",
                      backgroundColor: "#070B28",
                      borderRadius: 10,
                      padding: 10,
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* <Text style={{ color: "white", fontFamily: "poppins", fontSize: 18, paddingLeft: 10, }}>
                    Login
                  </Text> */}
                    <Text
                      style={{
                        color: "white",
                        fontFamily: "poppins",
                        fontSize: 18,
                        paddingLeft: 10,
                      }}
                    >
    {canRequestOTP ? 'Login' : `Wait ${remainingTime} seconds`}                    </Text>
                    <Image
                      source={require("../../assets/images/loginrightarrow.png")}
                      style={{ width: 20, height: 10, marginTop: 8, paddingRight: 10 }}
                    />
                  </TouchableOpacity>
                  {isPopoverOTPVisible && (
                    <Modal animationType="none" transparent={true} visible={isPopoverOTPVisible}>
                      <View style={{ flex: 1 }}>
                        <View
                          style={{
                            height: "100%",
                            backgroundColor: "white",
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                            padding: 10,
                          }}
                        >
                          <View
                            style={{
                              width: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: 50,
                            }}
                          >
                            <Image
                              source={require("../../assets/images/OTPVarify.png")}
                              style={{ width: 100, height: 100 }}
                            />
                            <Text
                              style={{
                                fontSize: 20,
                                fontWeight: "900",
                                fontFamily: "",
                                marginTop: 20,
                              }}
                            >
                              Verification Code{" "}
                            </Text>
                            <Text
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                textAlign: "center",
                                width: "80%",
                              }}
                            >
                              Please type the Verification code sent to {mobileNumber}
                            </Text>
                          </View>
                          <View style={{ marginTop: 40, alignItems: "center" }}>
                            {OtpError !== "" && (
                              <Text style={{ color: "red", marginTop: 5 }}>{OtpError}</Text>
                            )}
                          </View>
                          <TextInput
                            placeholder="Enter OTP"
                            value={otp}
                            onChangeText={handleOTPChange}
                            style={{
                              width: "100%",
                              marginTop: 10,
                              padding: 10,
                              borderWidth: 1,
                              borderColor: "#d5d7e3",
                              borderRadius: 5,
                              alignItems: "center",
                              textAlign: "center",
                            }}
                            autoComplete="one-time-code"
                            keyboardType="numeric"
                          />
                          <View style={{ marginTop: 20 }}>
                            <TouchableOpacity
                              onPress={handleVerify}
                              style={{
                                backgroundColor: isOTPFilled ? "orange" : "#ECECEC",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: 10,
                              }}
                            >
                              <Text style={{ fontFamily: "" }}>Verify</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                              onPress={hidePopover}
                              style={{
                                backgroundColor: "#EAFAFF",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: 10,
                                marginTop: 10,
                              }}
                            >
                              <Text style={{ fontFamily: "" }}>Resend Otp</Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                    </Modal>
                  )}
                </View>
                <View style={{ justifyContent: "center", alignItems: "center" }}>
                  <Text
                    style={{
                      width: 250,
                      textAlign: "center",
                      fontFamily: "Poppins",
                      marginTop: 20,
                    }}
                  >
                    By Login, I agree to P9Games{" "}
                    <Text
                      style={{ textDecorationLine: "underline", color: "blue" }}
                      onPress={() => Linking.openURL("https://p9games.com/terms-and-conditions")}
                    >
                      Terms and Conditions
                    </Text>{" "}
                    and{" "}
                    <Text
                      style={{ textDecorationLine: "underline", color: "blue" }}
                      onPress={() => Linking.openURL("https://p9games.com/privacy-policy")}
                    >
                      Privacy Policy
                    </Text>
                  </Text>
                </View>
              </View>
              <View style={{ width: "100%", position: "absolute", top: 230 }}>
                <Image
                  source={require("../../assets/images/loginLogo.png")}
                  style={{ width: "100%", height: 100, resizeMode: "contain" }}
                />
              </View>
            </View>
          </>
        )}
      </ScrollView>
    )
  })
